import React from "react"
import Carousel from 'react-bootstrap/Carousel';

class CarouselComp extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Carousel controls={false} wrap={false}>
          {this.props.slides.map(({ node }, index) => (
            <Carousel.Item key={node.uid} className="slide">
              <img
                className="d-block w-100"
                src={node.data.background_image.url}
                alt={`${node.data.title.text} slide`}
              />
              <div className="info">
                <img src={node.data.image.url} alt={`${node.data.title.text} slide content`}/>
                <h1 style={{color: node.data.title_color ? node.data.title_color : '#000'}}>{node.data.title.text}</h1>
                <p>{node.data.copy.text}</p>
                <a className="btn btn-primary" href={node.data.button_link.url} target={node.data.button_link.target}>
                  {node.data.button_text.text}
                </a>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </>
    )
  }

}

export default CarouselComp

