import React from "react"
import { graphql } from "gatsby"
import Helmet from 'react-helmet'

import Layout from "../components/layout";
import SEO from "../components/seo";
import ScrollAnimation from 'react-animate-on-scroll';
import "../styles/index.scss";
import Carousel from "../components/carousel";
import logo from "../images/logos/logo-white.png";

import IconCard from "../components/iconCard/iconCard";
import Retail from "../images/retail.inline.svg";
import Enterprise from "../images/enterprise.inline.svg";
import CStore from "../images/c-store.inline.svg";
import Restaurant from "../images/restaurant.inline.svg";
import ContentBlock from "../components/contentBlock/contentBlock";
import Heading from "../components/heading/heading";
import ContactForm from "../components/contact-form"

const IndexPage = ({ data }) => (
  <div className="home">
    <Layout>
      <SEO title="RetailStack" keywords={[`retail stack`]} />
      <Helmet bodyAttributes={{class: 'home-page'}} />
      <img src={logo} className="bg-logo" alt="bg logo" />
      <Carousel slides={data.allPrismicSlide.edges} />
      <ScrollAnimation className="our-suite" animateIn="fadeInUp" animateOnce={true}>
        <Heading
          title="Our Suite"
          copy="Our mission is to revolutionize the way retail businesses connect customers and products by providing a modern, open, enterprise-scale transaction platform. The RetailStack Commerce Platform offers a rich set of out-of-the-box features, with core products benefiting the RETAIL, C-STORES, RESTAURANTS, AND DISPENSED FUEL industries."
          align="center"
        />
      </ScrollAnimation>
      <div className="row mb-5">
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<Retail/>}
            copy="A single platform for traditional and digital commerce."
            link="/industries/retail"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<CStore/>}
            copy="Innovations that fuel growth from the forecourt to the back office."
            link="/industries/c-store"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<Restaurant/>}
            copy="Built in benefits for complete control over your entire business."
            link="/industries/restaurant"
          />
        </div>
        <div className="col-sm-12 col-md-6 col-lg-3">
          <IconCard 
            icon={<Enterprise/>}
            copy="Above store management, rich reporting and more."
            link="/industries/enterprise"
          />
        </div>
      </div>
      <div className="home-content-blocks"> 
        <ContentBlock
          imageCol="right"
          title="Curbside"
          copy="Retailers face the challenge of quickly adapting to changes in consumer purchasing behaviors, including an increased demand for curbside, delivery and other contactless order modes. Learn how our platform’s commitment to open APIs allows restaurants to adapt faster to changing consumer demands!"
          link="/industries/restaurant"
          imageName="home-curbside.png"
        />
        <ContentBlock
          imageCol="left"
          title="Customers First"
          copy="Traditional enterprise POS solutions focus on transactions first by treating sales as discrete events that always happen at a fixed position within a store. We’re challenging that dynamic because in today’s world commerce happens everywhere and customers are the most important part of the sale."
          link="/industries/enterprise"
          imageName="home-customers-first.png"
        />
      </div>
      <ScrollAnimation className="row greenBg" animateIn="fadeInUp" animateOnce={true} style={{marginBottom: 150}}>
          <div className="col-sm-12 col-md-10">
            <Heading
              title="Request a FREE Consultation"
              //copy="Our RetailStack Commerce Platform is designed with every need a client may have in mind. This is done with open APIs, which allows for first- and third-party integrations. You name it, we can integrate it into your POS system. We want our platform to feel uniquely yours!"
              styleKey="secondary"
              white={true}
              marginBottom={5}
            />
            <div className="row mt-4">
              <div className="col-sm-12 col-md-12 col-lg-7">
                <ContactForm />
              </div>
            </div>
          </div>
        </ScrollAnimation>
      
      {/* <ScrollAnimation  animateIn="fadeIn" animateOnce={true}>
        <Heading
          styleKey="secondary"
          title="Recent Posts"
          copy="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          align="center"
        />
        <div className="text-center mb-5">
          [Recent Posts]
        </div>
      </ScrollAnimation> */}
    </Layout>
  </div>
)

export default IndexPage

export const query = graphql`
query {
  allPrismicBlogPost(
    sort: {fields: data___publish_date, order: DESC}, 
    limit: 1, 
    filter: {data: {featured: {eq: "Yes"}}}
  ) {
    edges {
      node {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
        }
      }
    }
  },
  allPrismicSlide(
    sort: { fields: data___sort_order, order: ASC}
  ) {
    edges {
      node {
        uid
        data {
          background_image {
            url
          },
          image {
            url
          }
          title {
            text
          }
          title_color
          copy {
            text
          }
          button_text {
            text
          }
          button_link {
            url
          }
        }
      }
    }
  }
}
`
